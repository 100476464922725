import './bootstrap'
import 'v-calendar/dist/style.css'
import {createApp, h} from 'vue'
import {createPinia} from 'pinia'
import {createInertiaApp, usePage} from '@inertiajs/vue3'
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers'
import {SetupCalendar} from 'v-calendar'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

Bugsnag.start({
  apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
  appVersion: import.meta.env.VITE_APP_VERSION,
  plugins: [new BugsnagPluginVue()],
  onError: (event) => {
    const user = usePage()?.props?.user

    if (user) {
      event.setUser(user.id, user.name, user.email)
    }
  },
})

const bugsnagVue = Bugsnag.getPlugin('vue')
const pinia = createPinia()

createInertiaApp({
  title: (title) => `${title}`,
  progress: {
    color: '#4B5563',
  },
  resolve: (name) =>
    resolvePageComponent(
      `./Pages/${name}.vue`,
      import.meta.glob(`./Pages/**/*.vue`)
    ),
  setup({el, App, props, plugin}) {
    return createApp({render: () => h(App, props)})
      .use(plugin)
      .use(bugsnagVue)
      .use(SetupCalendar, {})
      .use(pinia)
      .mixin({
        methods: {
          route,
          connectEchoChannels() {
            if (this.$page.props.user) {
              Echo.private(
                `App.Models.Team.${this.$page.props.user.current_team.id}`
              )
                .listen('.ProductCreated', (e) => {
                  console.log(e)
                  if (e.team_product_count == 1) {
                    console.log('First product added')
                    window.dataLayer = window.dataLayer || []
                    window.dataLayer.push({
                      event: 'First product added - step 4',
                    })
                  } else if (e.team_product_count == 2) {
                    console.log('Second product added')
                    window.dataLayer = window.dataLayer || []
                    window.dataLayer.push({
                      event: 'Second product added - step 5',
                    })
                  }
                })
                .listen('.TestGtmNotifications', (e) => {
                  console.log(e.eventName)
                  window.dataLayer = window.dataLayer || []
                  window.dataLayer.push({
                    event: e.eventName,
                  })
                })
              console.log('connect')
            }
          },

          disconnectEchoChannels() {
            if (this.$page.props.user) {
              Echo.private(
                `App.Models.Team.${this.$page.props.user.current_team.id}`
              ).stopListening('.ProductCreated')
              console.log('disconnect')
            }
          },

          __theme(key) {
            var theme = this.$page.props.store?.data.theme ?? 'default'
            var value = this.$page.props.themes[theme]
              ? this.$page.props.themes[theme][key]
              : this.$page.props.themes['default'][key]
            return value
          },

          /**
           *
           * TRANSLATE START
           * TODO: THIS SHOULD BE MOVED TO A MIXIN OR ITS EQUIVALENT IN VUE3
           *
           */
          /**
           * Translate the given key.
           */
          __(key, replace = {}) {
            var translation = this.$page.props.language[key]
              ? this.$page.props.language[key]
              : key
            Object.keys(replace).forEach(function (key) {
              translation = translation.replace(':' + key, replace[key])
            })

            return translation
          },
          /**
           * Translate the given key with basic pluralization.
           */
          __n(key, number, replace = {}) {
            var options = key.split('|')
            key = options[1]
            if (number == 1) {
              key = options[0]
            } else {
              key = options[1]
            }
            return this.__(key, replace)
          },
          /**
           *
           * TRANSLATE END
           *
           */
        },
      })
      .mount(el)
  },
})
